import React from "react";
import image from "./apron_bg.jpg";
import logo from "./FooterImages-thd_logo.jpg";
import "./App.css";

export const App = (props) => {
  let currentYear = new Date().getFullYear();

  if (props.renderCondition) {
    return <div></div>;
  } else {
    return (
      <>
        <div id="mainBox" className="mainBox">
          <div id="mainBoxContent" className="mainBoxContent">
            <div
              id="loginWindow"
              className="loginWindow"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div id="loginWindowTitle" className="loginWindowTitle">
                <img src={logo} alt="" />
                <span>myApron</span>
              </div>

              <a
                id="loginButton"
                className="loginButton"
                href="https://login.microsoftonline.com/login.srf?wa=wsignin1.0&whr=homedepot.com&wreply=https://portal.homedepot.com/sites/myapron_en_us"
              >
                Login Now
              </a>
              <div id="quickLinksContainer" className="quickLinksContainer">
                <ul
                  id="quickLinksList"
                  className="quickLinksList"
                  spacing="compact"
                >
                  <li>
                    <b>Quick Links</b>
                  </li>
                  <li>
                    <a
                      href="https://homedepot-sso.prd.mykronos.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Dimensions
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://homedepot-sso.prd.mykronos.com/quicktimestamp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Dimensions - Quick Timestamp
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://myapronlite.homedepot.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      myApron Lite
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wd5.myworkday.com/homedepot/d/home.htmld"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Workday
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="mainBoxFooter" className="mainBoxFooter">
            <div id="footerLinkContainer" className="footerLinkContainer">
              <ul id="footerLinkList" className="footerLinkList">
                <li>
                  <a
                    href="https://portal.homedepot.com/sites/myapron_en_us/SitePages/Help-and-Support.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Support
                  </a>
                </li>
                <li>
                  <a
                    href="https://portal.homedepot.com/sites/myapron_en_us/SitePages/Legal.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Legal
                  </a>
                </li>
                <li>
                  <a
                    href="https://corporate.homedepot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Built From Scratch
                  </a>
                </li>
                <li>
                  <a
                    href="https://ir.homedepot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Investor Relations
                  </a>
                </li>
              </ul>
              <ul id="footerLinkPasswordChanges" className="footerLinkList">
                <li>
                  <a
                    href="https://thdsaml.homedepot.com/ext/pwdreset/Identify?AdapterId=thdssofedvendors&TargetResource=https%3A%2F%2Fthdsaml.homedepot.com%2Fidp%2FGwAFv%2FresumeSAML20%2Fidp%2FSSO.ping&FlowId=ECF158CWgD"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Forgot Password{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://myfirstTHDpassword.homedepot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    First Time Log In
                  </a>
                </li>
              </ul>
            </div>

            <div id="copyrightContainer" className="copyrightContainer">
              @ 2002 - {currentYear} Home Depot Product Authority LLC
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default App;
