/**
 * Analyzes original IP address string and returns either "store" or "corp"
 *
 * @param origIP as a string
 * @returns associate type as a string
 */
 const getAssociateType = (origIP) => {
    let associateType = 'store';
    try {
        if (typeof origIP === 'string' && origIP.length > 0) {
            // IP range for Mexico Stores & MDC: 10.240.0.0 to 10.255.255.255
            // IP range for US & Canada stores: 10.128.0.0 to 10.255.255.255
            // splits IP string into an array of its parts
            const origIPPartsArr = origIP.split('.');

            if (origIPPartsArr && origIPPartsArr.length === 4) {
                const condition1 = parseInt(origIPPartsArr[0]) === 10;
                const condition2 = parseInt(origIPPartsArr[1]) >= 128 && parseInt(origIPPartsArr[1]) <= 255;
                const condition3 = parseInt(origIPPartsArr[2]) >= 0 && parseInt(origIPPartsArr[2]) <= 255;
                const condition4 = parseInt(origIPPartsArr[3]) >= 0 && parseInt(origIPPartsArr[3]) <= 255;

                // if all conditions are met, IP represents a store user else it's a corp user
                associateType = condition1 && condition2 && condition3 && condition4 ? 'store' : 'corp';
            }
        }
    } catch (err) {
        console.log('err:', err);
    }
    return associateType;
};

/**
 * Gets and returns one of four browser language selections in lowercase
 *
 * @returns browserLang as a string in lowercase
 */
const getBrowserLang = () => {
    let browserLang;
    try {
        const browserLangRaw = navigator.language.toLowerCase();

        if(browserLangRaw.indexOf('fr') > -1){
            browserLang = 'fr-ca'
        }else if(browserLangRaw.indexOf('es') > -1){
            browserLang = 'es-mx'
        }else if(browserLangRaw === 'en-ca' || browserLangRaw === 'en-us'){
            browserLang = browserLangRaw
        }else{
            browserLang = 'en-us'
        }
    } catch (err) {
        console.log('err:', err);
    }
    return browserLang;
};

/**
 *
 */
const getOriginalIP = () => {
    let originalIP = '';
    const originalIPKey = 'x-forwarded-for';
    try {
        let queryParamsRaw = window.location.search;
        if (
            queryParamsRaw &&
            queryParamsRaw.length > 1 &&
            queryParamsRaw.toLowerCase().indexOf(originalIPKey) > -1
        ){
            // removes the leading "?" and converts to lowercase
            queryParamsRaw = queryParamsRaw.substring(1).toLowerCase();

            // handles the potential existence of multiple query params
            let queryParamsArr = queryParamsRaw.split('&');

            // filters down to only key/value pairs containing the key of "x-forwarded-for"
            queryParamsArr = queryParamsArr.filter(keyValuePair => keyValuePair.indexOf(originalIPKey) > -1);

            // conditionally selects the 1st match found assuming at least one match is found
            if (queryParamsArr.length > 0) {
                // sets the value of originalIP to the 1st match trimmed for extra whitespace with the key portion and "=" stripped out
                originalIP = queryParamsArr[0].trim().replace(originalIPKey + '=', '');
            }
        }
    } catch (err) {
        console.log('err:', err);
    }
    return originalIP;
};

/**
 * handles redirecting the user to the right url depending on associateType and browserLang
 *
 * @param associateType
 * @param browserLang
 * @returns void
 */
const handleRedirect = async (associateType, browserLang) => {

    const hostname = window.location.hostname;
    const preAuthUrlRoot = hostname;

    let redirectUrl;
    try {
        if (associateType === 'corp' && browserLang !== 'en-us') {
            redirectUrl = redirectUrl.replace('en-us', browserLang);
        }else if(browserLang === 'en-us'){
            redirectUrl = hostname
        }
         else{ // presumed associateType of "store"
            redirectUrl = preAuthUrlRoot.replace('en-us', browserLang);
        }

        redirectUrl = 'https://' + redirectUrl + '/';
        redirectUrl = new URL(redirectUrl)
        let redirectUrlHref = redirectUrl.href;
        const currentUrl = window.location.href;
        let currentUrlHref = new URL(currentUrl).href

        let isRedirectNeeded

        if (currentUrlHref !== redirectUrlHref) {
            window.location.assign(redirectUrl);
            isRedirectNeeded =true
        }else{
            isRedirectNeeded = false 
        }
        return await Promise.resolve(isRedirectNeeded) 
        
    } catch (err) {
        console.log('err:', err);
    }
};

/**
 * init function called at start loading of script file
 *
 * @returns void
 */
const init = async () => {
    try {
        if (window.location.href.includes('en-us')) {
            // get environment
            //const env = getEnvironment();

            // grab x-forwarded-for from url query param
            const origIP = getOriginalIP();

            // get store vs corp by IP
            const associateType = getAssociateType(origIP);

            // grab browser language
            const browserLang = getBrowserLang();

            // function used to handle redirecting the user
            return await handleRedirect(associateType, browserLang);
        }
        
    } catch (err) {
        console.log('err:', err);
    }
};

export default init